body {
  font-family: 'Montserrat', sans-serif;
  font-weight: lighter;
}
::-webkit-scrollbar { 
  display: none;
}

.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
          line-clamp: 3; 
  -webkit-box-orient: vertical;
}

.boxHoverEffect {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: white;
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  letter-spacing: 2px;
  transition: 1s box-shadow;
}

.boxHoverEffect:hover {
  box-shadow: 0 5px 35px 0px rgba(0,0,0,.1);
}

.boxHoverEffect:hover::before, .boxHoverEffect:hover::after {
  display: block;
  content: '';
  position: absolute;
  background: #a8cafd!important;
  border-radius: 1rem;
  height: 100%;
  width: 100%;
  z-index: 1;
  animation: 1s clockwise infinite;
}

.boxHoverEffect:hover:after {
  background: #ffffff!important;
  animation: 2s counterclockwise infinite;
}

@keyframes clockwise {
  0% {
    top: -5px;
    left: 0;
  }
  12% {
    top: -2px;
    left: 2px;
  }
  25% {
    top: 0;
    left: 5px;    
  }
  37% {
    top: 2px;
    left: 2px;
  }
  50% {
    top: 5px;
    left: 0;    
  }
  62% {
    top: 2px;
    left: -2px;
  }
  75% {
    top: 0;
    left: -5px;
  }
  87% {
    top: -2px;
    left: -2px;
  }
  100% {
    top: -5px;
    left: 0;    
  }
}

@keyframes counterclockwise {
  0% {
    top: -5px;
    right: 0;
  }
  12% {
    top: -2px;
    right: 2px;
  }
  25% {
    top: 0;
    right: 5px;    
  }
  37% {
    top: 2px;
    right: 2px;
  }
  50% {
    top: 5px;
    right: 0;    
  }
  62% {
    top: 2px;
    right: -2px;
  }
  75% {
    top: 0;
    right: -5px;
  }
  87% {
    top: -2px;
    right: -2px;
  }
  100% {
    top: -5px;
    right: 0;    
  }
}